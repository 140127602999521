import { LeaveValue } from '@types';
import { Button, Modal, Space } from 'antd';
import { Dispatch, SetStateAction, useRef } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';

interface PreviewModalProps {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
	value: LeaveValue | undefined;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ visible, setVisible, value }) => {
	let componentRef = useRef<any>() as any;

	const getPageMargins = () => {
		return `@page { margin: 50px 30px !important; }`;
	};

	return (
		<Modal
			open={visible}
			onCancel={() => {
				setVisible(false);
			}}
			title="Preview Claim Form"
			footer={null}
			width={1100}
			style={{ top: 0 }}
		>
			<style>{getPageMargins()}</style>
			<div className="p-5 my-10 overflow-x-auto">
				<div className="mx-auto w-full min-w-[1000px] max-w-[1000px] bg-white" ref={(el) => (componentRef = el)}>
					<div id="header">
						{/* Stragify Hub Logo */}
						<div className="flex justify-between align-top">
							<div className="logo w-[200px]">
								<img src="stragify-hub.png" alt="Stragify Hub Logo" className="w-full" />
							</div>
							<div className="version">
								<p className="m-0 text-[12px] font-[500]">HR_LAF_V2_171022</p>
							</div>
						</div>
						{/* Address */}
						<div className="mt-3 address">
							<p className="text-[11px]">C-13A-10 Block C Suria Kipark Damansara Persiaran KIP 2 Taman KIP 52200 Kuala Lumpur</p>
						</div>
						{/* Title */}
						<div className="flex justify-end mt-2 title">
							<h1 className="text-3xl font-[500]">LEAVE APPLICATION FORM</h1>
						</div>

						{/* Applicant Form */}
						<div className="mt-2">
							<table className="w-full preview_applicant_table">
								<tbody>
									<tr>
										<td colSpan={2}>
											<label>NAME AS PER NRIC / PASSPORT:</label>
											<span className="value">{value?.name}</span>
										</td>
									</tr>
									<tr>
										<td className="!w-[50%]">
											<label>DEPARTMENT:</label>
											<span className="value">{value?.department}</span>
										</td>
										<td className="!w-[50%]">
											<label>DESIGNATION:</label>
											<span className="value">{value?.designation}</span>
										</td>
									</tr>
									<tr>
										<td className="!w-[50%]">
											<label>EMAIL:</label>
											<span className="value">{value?.email}</span>
										</td>
										<td className="!w-[50%]">
											<label>CONTACT NUMBER:</label>
											<span className="value">{value?.contactNumber}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					{/* Leave Form */}
					<div className="mt-[25px]">
						<div>
							<h2 className="underline">{value?.leaveType}</h2>
							<div>
								{value?.leaveType === 'Annual Leave' && (
									<>
										<p>
											<label className="!w-[125px] inline-block">Carry Forward</label>: <span className="font-bold">{value?.carryForward} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Entitlement</label>: <span className="font-bold">{value?.entitlement} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Applied</label>: <span className="font-bold">{value?.applied} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Total Apply</label>: <span className="font-bold">{value?.totalApply} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Balance</label>: <span className="font-bold">{value?.balance} Days</span>
										</p>
									</>
								)}

								{value?.leaveType === 'Medical Leave' && (
									<>
										<p>
											<label className="!w-[125px] inline-block">Entitlement</label>: <span className="font-bold">{value?.entitlement} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Applied</label>: <span className="font-bold">{value?.applied} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Total Apply</label>: <span className="font-bold">{value?.totalApply} Days</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Balance</label>: <span className="font-bold">{value?.balance} Days</span>
										</p>
									</>
								)}
								{value?.leaveType === 'Others' && (
									<>
										<p>
											<label className="!w-[125px] inline-block">Type of Leave</label>: <span className="font-bold">{value?.typeOfLeave}</span>
										</p>
										<p>
											<label className="!w-[125px] inline-block">Total Apply</label>: <span className="font-bold">{value?.totalApply} Days</span>
										</p>
									</>
								)}
								<p>
									<label className="!w-[125px] inline-block">Date</label>: <span className="font-bold">{value?.period?.[0].format('DD MMMM YYYY')}</span>
									<span className="font-bold w-[25px] !inline-block text-center">-</span>
									<span className="font-bold">{value?.period?.[1].format('DD MMMM YYYY')}</span>
								</p>
								<div className="border-[1px] border-solid border-black rounded p-5 bg-gray-100">
									<p className="m-0">{value?.reason}</p>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<p className="mb-0 text-sm font-bold">Notes:-</p>
							<ol>
								<li>
									All leave submission must be submitted before 5 working days from the leave start date. If the said submission is less than 5 working days, then approved can be
									rejected by Immediate Superior or Human Resources Department;
									<ul>
										<li>All approved / rejected submission will be notified via email.</li>
									</ul>
								</li>
								<li>
									Medical Certificate needs to be attached together with the submission, if you are applying the medical leave;
									<ul>
										<li>Those submissions without Medical Certificate need to seek advice from Human Resource before submission;</li>
										<li>
											The Company reserves to right to reject the submission, if there is forge/dishonesty/misuse found in that submission. Furthermore, The Company reserves the
											right to take further legal action against any forge/dishonesty/misuse submission.
										</li>
									</ul>
								</li>
								<li>Employee can only submit own leave application and NOT allowed to submit on behalf of other employees;</li>
								<li>Leave entitlement can be refer to your Letter of Employment;</li>
								<li>
									All employees are allowed to carry forward the annual leave to next service year;
									<ul>
										<li>Only 50% of the annual leave entitlement will be allowed;</li>
										<li>The said carry forward annual leave need to be cleared by quarter 2 of the next service year, otherwise the said leave will be forfeited;</li>
										<li>
											If the employee needs to extend the said leave more than quarter 2, then need to inform Immediate Superior and Human Resource in prior by written notice to
											obtain approval;
										</li>
									</ul>
								</li>
								<li>Terms and Conditions are subject to change.</li>
							</ol>
						</div>
					</div>

					{/* Signature Form */}
					<div className="mt-5">
						<table className="w-full preview_signature_table">
							<tbody>
								<tr>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">APPLICANT&apos;S SIGNATURE</label>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">IMMEDIATE SUPERIOR&apos;S SIGNATURE</label>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">HUMAN RESOURCE&apos;S SIGNATURE:</label>
										<div>
											<label>
												<input type="checkbox" className="mr-2 align-middle" disabled />
												<span className="align-middle">APPROVED</span>
											</label>
										</div>
										<div>
											<label>
												<input type="checkbox" className="mr-2 align-middle" disabled />
												<span className="align-middle">REJECTED</span>
											</label>
										</div>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">DIRECTOR&apos;S SIGNATURE:</label>
									</td>
								</tr>
								<tr>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
								</tr>
								<tr>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
								</tr>
								<tr>
									<td className="align-top h-[70px]" colSpan={4}>
										<label className="font-bold">FOR HUMAN RESOURCE USE (REJECTED REASON) :-</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="flex justify-end">
				<Space>
					<Button size="large" onClick={() => setVisible(false)}>
						Cancel
					</Button>
					<ReactToPrint
						trigger={() => (
							<Button size="large" type="primary" icon={<PrinterOutlined />}>
								Print
							</Button>
						)}
						content={() => componentRef as any}
					/>
				</Space>
			</div>
		</Modal>
	);
};

export default PreviewModal;
