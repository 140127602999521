import ClaimForm from './components/claimForm';
import LeaveForm from './components/leaveForm';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import './index.css';
import { Card, Col, Divider, Row } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

function App() {
	const isMobile = useMediaQuery({ query: '(max-width: 1050px)' });

	return (
		<Router>
			<div className="flex" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
				{/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */}
				{!isMobile ? (
					<div className="bg-white shadow-[rgba(100,100,111,0.2)_0_7px_29px_0] fixed h-screen z-10" style={{ width: isMobile ? 0 : 250 }}>
						<div className="w-full px-5 pt-6 logo">
							<img src="stragify-hub.png" alt="Stragify Hub Logo" className="w-full" />
						</div>
						<Divider />
						<nav>
							<NavLink className="desktop" to="/">
								<div className="w-full px-5 py-4">Home</div>
							</NavLink>
							<NavLink className="desktop" to="/claim-form">
								<div className="w-full px-5 py-4">Claim Form</div>
							</NavLink>
							<NavLink className="desktop" to="/leave-form">
								<div className="w-full px-5 py-4">Leave Form</div>
							</NavLink>
						</nav>
					</div>
				) : (
					<div className="">
						<div className="fixed flex justify-center w-full px-3 py-5 !bg-white z-10 shadow-[rgba(100,100,111,0.2)_0_7px_29px_0]">
							<NavLink className="mobile" to="/">
								<div>Home</div>
							</NavLink>
							<NavLink className="mobile" to="/claim-form">
								<div>Claim Form</div>
							</NavLink>
							<NavLink className="mobile" to="/leave-form">
								<div>Leave Form</div>
							</NavLink>
						</div>
						<Divider className="!my-1" />
					</div>
				)}
				<main className="w-full" style={{ marginLeft: isMobile ? 0 : 250 }}>
					<Routes>
						<Route index element={<EmptyComponent />} />
						<Route path="/claim-form" element={<ClaimForm />} />
						<Route path="/leave-form" element={<LeaveForm />} />
					</Routes>
				</main>
			</div>
		</Router>
	);
}

const EmptyComponent = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 1050px)' });
	return (
		<>
			<Helmet>
				<title>Stragify Hub</title>
			</Helmet>
			<div className="p-10" style={{ marginTop: isMobile ? 66.5 : 0 }}>
				<Row gutter={[16, 16]}>
					<Col xs={24} sm={24} md={12} lg={12} xl={8}>
						<Link to="/claim-form">
							<Card hoverable className="!bg-white">
								<div className="flex items-center justify-between">
									<label className="text-[20px] font-bold text-blue-400">Claim Form</label>
									<ProfileOutlined className="text-[50px] text-blue-400" />
								</div>
							</Card>
						</Link>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={8}>
						<Link to="/leave-form">
							<Card hoverable className="!bg-white">
								<div className="flex items-center justify-between">
									<label className="text-[20px] font-bold text-red-400">Leave Form</label>
									<ProfileOutlined className="text-[50px] text-red-400" />
								</div>
							</Card>
						</Link>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default App;
