import { ClaimValue } from '@types';
import { Button, Modal, Space } from 'antd';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';

interface PreviewModalProps {
	visible: boolean;
	setVisible: Dispatch<SetStateAction<boolean>>;
	value: ClaimValue | undefined;
}

const PreviewModal: React.FC<PreviewModalProps> = ({ visible, setVisible, value }) => {
	const [total, setTotal] = useState<number>(0);
	let componentRef = useRef<any>() as any;

	useEffect(() => {
		let total = 0;
		if (!value?.claim) return;
		value?.claim.forEach((claim) => {
			total += claim.amount;
		});
		setTotal(total);
	}, [value]);

	const getPageMargins = () => {
		return `@page { margin: 50px 30px !important; }`;
	};

	return (
		<Modal
			open={visible}
			onCancel={() => {
				setVisible(false);
			}}
			title="Preview Claim Form"
			footer={null}
			width={1100}
			style={{ top: 0 }}
		>
			<style>{getPageMargins()}</style>
			<div className="p-5 my-10 overflow-x-auto">
				<div className="mx-auto w-full min-w-[1000px] max-w-[1000px] bg-white" ref={(el) => (componentRef = el)}>
					<div id="header">
						{/* Stragify Hub Logo */}
						<div className="flex justify-between align-top">
							<div className="logo w-[200px]">
								<img src="stragify-hub.png" alt="Stragify Hub Logo" className="w-full" />
							</div>
							<div className="version">
								<p className="m-0 text-[12px] font-[500]">Fin_CF_V1_010622</p>
							</div>
						</div>
						{/* Address */}
						<div className="mt-3 address">
							<p className="text-[11px]">C-13A-10 Block C Suria Kipark Damansara Persiaran KIP 2 Taman KIP 52200 Kuala Lumpur</p>
						</div>
						{/* Title */}
						<div className="flex justify-end mt-2 title">
							<h1 className="text-3xl font-[500]">CLAIM FORM</h1>
						</div>

						{/* Applicant Form */}
						<div className="mt-2">
							<table className="w-full preview_applicant_table">
								<tbody>
									<tr>
										<td colSpan={2}>
											<label>NAME AS PER NRIC / PASSPORT:</label>
											<span className="value">{value?.name}</span>
										</td>
									</tr>
									<tr>
										<td className="!w-[50%]">
											<label>DEPARTMENT:</label>
											<span className="value">{value?.department}</span>
										</td>
										<td className="!w-[50%]">
											<label>DESIGNATION:</label>
											<span className="value">{value?.designation}</span>
										</td>
									</tr>
									<tr>
										<td className="!w-[50%]">
											<label>EMAIL:</label>
											<span className="value">{value?.email}</span>
										</td>
										<td className="!w-[50%]">
											<label>CONTACT NUMBER:</label>
											<span className="value">{value?.contactNumber}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					{/* Claim Form */}
					<div className="mt-[25px]">
						<table className="w-full preview_claim_table">
							<thead>
								<tr>
									<th className="!w-[5%]">No.</th>
									<th className="!w-[40%]">DESCRIPTION & PURPOSE OF CLAIM</th>
									<th className="!w-[15%]">DATE</th>
									<th className="!w-[20%]">INVOICE #</th>
									<th className="!w-[15%]">AMOUNT (RM)</th>
								</tr>
							</thead>
							<tbody>
								{value?.claim.map((claim, index) => (
									<tr key={index}>
										<td className="!w-[3%] text-center">{index + 1}</td>
										<td className="!w-[56%]">{claim.description}</td>
										<td className="!w-[12%] text-center">{claim?.date?.format('DD MMM YYYY')}</td>
										<td className="!w-[17%] text-center">{claim.invoice}</td>
										<td className="!w-[12%] text-center">{claim.amount.toFixed(2)}</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td className="!w-[5%] text-right" colSpan={4}>
										Total
									</td>
									<td className="!w-[5%] text-center bg-gray-300">{total.toFixed(2)}</td>
								</tr>
							</tfoot>
						</table>
						<div className="mt-5">
							<p className="mb-0 text-sm font-bold">Notes:-</p>
							<ol>
								<li>
									All claims submission must be submit before 15th of the month. Those submitted after 15th will be processed by the following month;
									<ul>
										<li>All claims will be taking 14 working days to process and those approved / rejected submission will be notified via email.</li>
									</ul>
								</li>
								<li>
									Receipt/Invoice/Proof need to be attached together with the claim form and approved by immediate superior before submit to Finance Department;
									<ul>
										<li>Those claim without Receipt/Invoice/Proof, please do seek advice from Finance before submission.</li>
										<li>
											The Company reserves to right to reject the submission, if there is forge/dishonesty/misuse found in that submission. Furthermore, The Company reserves the
											right to take further legal action against any forge/dishonesty/misuse submission.
										</li>
										<li>Employee can only submit own claim as the amount will be credited to your bank account.</li>
									</ul>
								</li>
								<li>Terms and Conditions are subject to change.</li>
							</ol>
						</div>
					</div>

					{/* Signature Form */}
					<div className="mt-5">
						<table className="w-full preview_signature_table">
							<tbody>
								<tr>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">CLAIMER&apos;S SIGNATURE</label>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">IMMEDIATE SUPERIOR&apos;S SIGNATURE</label>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">FINANCE&apos;S SIGNATURE:</label>
										<div>
											<label>
												<input type="checkbox" className="mr-2 align-middle" disabled />
												<span className="align-middle">APPROVED</span>
											</label>
										</div>
										<div>
											<label>
												<input type="checkbox" className="mr-2 align-middle" disabled />
												<span className="align-middle">REJECTED</span>
											</label>
										</div>
									</td>
									<td className="!w-[25%] h-[70px] align-top">
										<label className="font-bold">DIRECTOR&apos;S SIGNATURE:</label>
									</td>
								</tr>
								<tr>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">NAME:</label>
									</td>
								</tr>
								<tr>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
									<td className="!w-[25%] align-top">
										<label className="font-bold">DATE:</label>
									</td>
								</tr>
								<tr>
									<td className="align-top h-[70px]" colSpan={4}>
										<label className="font-bold">FOR FINANCE USE (REJECTED REASON) :-</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="flex justify-end">
				<Space>
					<Button size="large" onClick={() => setVisible(false)}>
						Cancel
					</Button>
					<ReactToPrint
						trigger={() => (
							<Button size="large" type="primary" icon={<PrinterOutlined />}>
								Print
							</Button>
						)}
						content={() => componentRef as any}
					/>
				</Space>
			</div>
		</Modal>
	);
};

export default PreviewModal;
