import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Empty, Form, FormInstance, Input, Row, Select } from 'antd';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AnnualLeave from './AnnualLeave';
import MedicalLeave from './MedicalLeave';
import OthersLeave from './OthersLeave';

interface LeaveFormProps {
	form: FormInstance;
	onGeneratePdf: () => void;
}

const LeaveForm: React.FC<LeaveFormProps> = ({ form, onGeneratePdf }) => {
	const [leaveType, setLeaveType] = useState<'Annual Leave' | 'Medical Leave' | 'Others' | ''>('');

	return (
		<div className="p-5">
			<div id="header">
				<div className="logo w-[200px]">
					<img src="stragify-hub.png" alt="Stragify Hub Logo" className="w-full" />
				</div>
				<div className="mt-3 address">
					<p className="text-[11px]">C-13A-10 Block C Suria Kipark Damansara Persiaran KIP 2 Taman KIP 52200 Kuala Lumpur</p>
				</div>
				<div className="flex justify-end mt-5 title">
					<h1 className="text-3xl font-[500]">LEAVE APPLICATION FORM</h1>
				</div>
			</div>
			<Divider />
			<div id="applicant_profile">
				<Form form={form} layout="vertical">
					<Row gutter={[16, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Form.Item label="Name as per NRIC / Passport" name="name" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Department" name="department" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Designation" name="designation" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Email" name="email" rules={[{ required: true }, { type: 'email', message: 'Invalid Email' }]}>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Form.Item label="Contact Number" name="contactNumber" rules={[{ required: true }]}>
								<PhoneInput country="my" />
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<Form.Item name="leaveType">
						<Select placeholder="Select Leave Type" className="!w-[210px]" onChange={(value) => setLeaveType(value)} value={leaveType}>
							<Select.Option value="">-- Select Leave Type --</Select.Option>
							<Select.Option value="Annual Leave">Annual Leave</Select.Option>
							<Select.Option value="Medical Leave">Medical Leave</Select.Option>
							<Select.Option value="Others">Others</Select.Option>
						</Select>
					</Form.Item>
					<div className="my-7">
						{leaveType === '' && (
							<div>
								<Empty description="Please select a leave type" />
							</div>
						)}
						{leaveType === 'Annual Leave' && <AnnualLeave />}
						{leaveType === 'Medical Leave' && <MedicalLeave />}
						{leaveType === 'Others' && <OthersLeave />}
					</div>
				</Form>
			</div>
			<div></div>
			<div className="flex justify-end">
				<Button size="large" type="primary" onClick={onGeneratePdf} icon={<FilePdfOutlined />} disabled={leaveType === ''}>
					Generate PDF
				</Button>
			</div>
		</div>
	);
};

export default LeaveForm;
